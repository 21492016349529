<template>
  <div class="flex-column" v-loading="loading">
    <sticky :className="'sub-navbar'">
      <div class="filter-container">
        <!-- <base-search :searchList="$bbglYjxlljSearch" :listQuery='listQuery' v-on:search='handleFilter' v-on:btn-event="onBtnClicked" style="display: inline-block;" :noOperation="true"></base-search> -->
        <div class="search_box">
          <div class="baseSearch">
            <el-button class="filter-item" size="mini" type="primary" plain @click="chooseDate('today')">今天</el-button>
            <el-button class="filter-item" size="mini" type="primary" plain @click="chooseDate('yesterday')">昨天</el-button>
            <el-button class="filter-item" size="mini" type="primary" plain @click="chooseDate('sevenTime')" style="margin-right:10px;">最近七天</el-button>
            <el-date-picker v-model="listQuery.timer" type="datetimerange" :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right" style="margin-right:10px;width:450px;" :default-time="['01:00:00', '08:00:00']" @change="changeTime"> </el-date-picker>
            <span style="margin-right:5px;color:#303133;font-size:14px;">采集周期:</span>
            <el-select v-model="listQuery.searchType" placeholder="采集周期" style="margin-right:10px;" >
              <el-option v-for="(j,jdx) in options" :key="jdx" :label="j.name" :value="j.dtCode"> </el-option>
            </el-select>
            <el-button class="filter-item" size="mini" type="primary" v-waves icon="el-icon-search" @click="handleFilter">搜索</el-button>
          </div>
        </div>

      </div>
    </sticky>
    <div class="app-container flex-item">
      <!-- 拖拽div -->
      <div class="dragBox" ref="dragBox">
        <div class="dragBox_left">
          <!--左侧div内容-->
          <el-card shadow="never" class="body-small" style="height: 100%;overflow:auto;">
            <div v-if="orgsTree.length>0">
              <basic-tree :Treedata="orgsTree" :isShowCheck="false" @handleNodeClick="handleNodeClick" @getCheckedKeys="getCheckedKeys" :hasIcon="true" @nodeClickOne="nodeClickOne"></basic-tree >
            </div>
            
          </el-card>
        </div>
        <div class="dragBox_resize" title="收缩侧边栏">
          ⋮
        </div>
        <div class="dragBox_mid">
          <!--右侧div内容-->
          <div class="bg-white" style="height: 100%;" id="table_box_height">
            <el-row style="height: 100%;">
            <el-col :span="8" style="height: 100%;">
              <div class="bg-white" style="height: 100%;" id="table_box_height">
                <basic-table :tableHeight="tableHeight" :tableHeaderList="firstHeaderList" :tableData="tableData" :listQuery="listQuery" :hasPagination="false" @tableRowClick="tableRowClick"></basic-table >
              </div>
            </el-col>
            <el-col :span="16" style="height: 100%;">
              <el-card>
                <div id="realChartYjxll" :style="{width: '100%', height: tableHeight - 50 +'px',}"></div>
              </el-card>
            </el-col>
          </el-row>
          </div>
        </div>
      </div>
      <!-- 拖拽div -->

      <!-- <el-row style="height: 100%;" :gutter="8" >
        <el-col :span="4" style="height: 100%;">
          <el-card shadow="never" class="body-small" style="height: 100%;overflow:auto;">
            <basic-tree :Treedata="orgsTree" :isShowCheck="true" @handleNodeClick="handleNodeClick" @getCheckedKeys="getCheckedKeys" :hasIcon="true"></basic-tree >
          </el-card>
        </el-col>
        <el-col :span="20" style="height: 100%;">
          <el-row style="height: 100%;">
            <el-col :span="8" style="height: 100%;">
              <div class="bg-white" style="height: 100%;" id="table_box_height">
                <basic-table :tableHeight="tableHeight" :tableHeaderList="firstHeaderList" :tableData="tableData" :listQuery="listQuery" :hasPagination="false" @tableRowClick="tableRowClick"></basic-table >
              </div>
            </el-col>
            <el-col :span="16" style="height: 100%;">
              <el-card>
                <div id="realChartYjxll" :style="{width: '100%', height: tableHeight - 50 +'px',}"></div>
              </el-card>
            </el-col>
          </el-row>
          
        </el-col>
      </el-row> -->
    </div>

  </div>

</template>

<script>

  import * as echarts from 'echarts';
  import basicTree from './tree'//树形组件
  import basicTable from './table'//表格组件
  
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
  import {listToTreeSelectNum} from '@/utils'//树结构转换

  
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import waves from '@/directive/waves' // 水波纹指令
  import Sticky from '@/components/Sticky'
  import elDragDialog from '@/directive/el-dragDialog'
  export default {
    name: 'hdybqygl',
    components: {
      Sticky,
      basicTree,
      basicTable,
    },
    directives: {
      waves,
      elDragDialog
    },
    data() {
      var that = this
      return {
        loading:false,
        listQuery: { // 查询条件
          page: 1,
          limit: 50,
          timer:'',
          TreeIds:[],
          beginTime:'',
          endTime:'',
          searchType:1,//采集周期
        },
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              var time = that.timeEXchange(start,end)
              picker.$emit('pick',time);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              var time = that.timeEXchange(start,end)
              picker.$emit('pick',time);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              var time = that.timeEXchange(start,end)
              picker.$emit('pick',time);
            }
          }]
        },
        options:[
          {name:'半小时/次',dtCode:1,},
          {name:'每小时/次',dtCode:2,},
          {name:'每天/次',dtCode:3,},
        ],
        tableData:[],
        tableHeight:700,//表格高度
        CheckedKey:[],//选中数据的id集合
        tableChooseRow:{},//table选中数据
        realChartYjxll:'',//顶部折现图-用于屏幕放大缩小重新渲染
        firstHeaderList:[],// 主列表列定义
        xDatas:[],
        y1Datas:[],
        y2Datas:[],
        orgsTree:[],//左侧总表数
        mainWaterMeter:[],//选中的总表
        chooseNode:{},//左侧树选中的节点
        rangeTimeB:"20:00:00",
        rangeTimeA:"08:00:00",
      }
    },
    watch: {

    },
    computed: {
      // orgsTree() {
      //   return this.$store.state.AllArea;
      // },
    },
    filters: {
      
    },
    created() {
      this.getTree()
    },
    mounted() {
      // 计算table高度
      this.$nextTick(()=>{
        let ele=document.getElementById("table_box_height");
        this.tableHeight=ele.offsetHeight
        window.addEventListener('resize', () => { 
          let ele=document.getElementById("table_box_height");
          this.tableHeight=ele.offsetHeight
          this.realChartYjxll.resize();
          this.$forceUpdate()
        });
      })
      
      this.$basicFun.dragControllerDiv();//拖拽div方法

      var today = new Date();
      var yesterday = this.$basicFun.getDay(-1);
      this.listQuery.timer = this.timeEXchange(yesterday,today)
    },
    methods: {
      //选择时间后更新时间取值范围
      changeTime(val){
        var s1 = this.$basicFun.dataFormat(val[0],'yyyy-MM-dd hh:mm:ss')
        var s2 = this.$basicFun.dataFormat(val[1],'yyyy-MM-dd hh:mm:ss')
        this.rangeTimeB = s1.substr(11,18)
        this.rangeTimeA = s2.substr(11,18)
      },
      // 时间范围截取转换
      timeEXchange(day1,day2){
        var t1 = this.$basicFun.dataFormat(day1,'yyyy-MM-dd hh:mm:ss')
        var t2 = this.$basicFun.dataFormat(day2,'yyyy-MM-dd hh:mm:ss')
        t1 = t1.substr(0,11) + this.rangeTimeB
        t2 = t2.substr(0,11) + this.rangeTimeA
        var s1 = new Date(t1)
        var s2 = new Date(t2)
        var time = [s1,s2]
        return time
      },
      //选择时间
      chooseDate(time){
        var today = new Date();
        var yesterday = this.$basicFun.getDay(-1);
        var beforeYesterday = this.$basicFun.getDay(-2);
        var sevenTime = this.$basicFun.getDay(-7);
        if(time == 'today'){
          // this.listQuery.timer = [yesterday,today]
          this.listQuery.timer = this.timeEXchange(yesterday,today)
        }
        if(time == 'yesterday'){
          // this.listQuery.timer = [beforeYesterday,yesterday]
          this.listQuery.timer = this.timeEXchange(beforeYesterday,yesterday)
        }
        if(time == 'sevenTime'){
          // this.listQuery.timer = [sevenTime,today]
          this.listQuery.timer = this.timeEXchange(sevenTime,today)
        }
        this.handleFilter()
      },
      //获取树
      getTree(){
        basicDataApi.zbglGetList().then(response => {
          if(response.code == 200){
            var s  = response.data
            // // 过滤大表
            // var memter = JSON.parse(JSON.stringify(s)).filter(i=>{
            //   return i.areaNum == "▩"
            // })
            // // 找出大表所属区域
            // var fatherNode = s.filter(item=> memter.some(ele=>ele.fatherNode == item.id))
            
            // // 合并数据
            // var allList = fatherNode.concat(memter)
            
            var Process = s.map(function(item) {
              return {
                id: item.id,
                childId: item.id,
                label: item.areaName,
                areaNum: item.areaNum,
                parentId: item.fatherNode || null,
                meterReading: item.meterReading,
              }
            })
            var orgstmp = JSON.parse(JSON.stringify(Process))
            this.orgsTree = listToTreeSelectNum(orgstmp)
            console.log(this.orgsTree,33)
          }
        }).catch(()=>{
          this.orgsTree = []
        })
      },
      //获取最近30分钟访问情况数据
      getrealChartYjxll(){
        // type:1初始化数据，2实时刷新数据
        // 最近30分钟访问情况
        this.realChartYjxll = echarts.init(document.getElementById('realChartYjxll'));
        this.realChartYjxll.setOption({
          tooltip: {
            trigger: 'axis',
          },
          grid:{//图表位置
            top:'15%',
            bottom:'15%',
            right:'5%'
          },
          legend: {
            data: ['累计流量', '间隔差量'],
            x:'center',      //可设定图例在左、右、居中
            y:'bottom',     //可设定图例在上、下、居中
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,//坐标轴两边留白
              axisTick: {show: false},
              // data: ['00:00', '01:00', '02:00','03:00','04:00', '05:00', '06:00', '07:00',]
              data: this.xDatas
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name:'间隔差量',
              type: 'line',
              itemStyle: {
                color:'#b694f0',
              },
              lineStyle: {
                color: "#b694f0",
                width:1
              },
              areaStyle: { 
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{offset: 0,color: 'rgba(182,148,240,0.3)'},{offset: 1,color: 'rgba(182,148,240,1)'}]),
              },
              data: this.y2Datas
            },
            {
              name:'累计流量',
              type: 'line',
              itemStyle: {
                color:'#00a7e2',
              },
              lineStyle: {
                color: "#00a7e2",
                width:1
              },
              areaStyle: { 
                color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{offset: 0,color: 'rgba(0,167,226,0.3)'}, { offset: 1,color: 'rgba(0,167,226,1)'}]),
              },
              data: this.y1Datas
            },
          ]
        })
      },
      // 节点点击
      handleNodeClick(ids,data) { 
        this.listQuery.TreeIds = ids
        this.mainWaterMeter = data//选中总表赋值
      },
      // 添加节点选中数据
      getCheckedKeys(data){
        console.log(data)
        // this.CheckedKey = data
        // var s = this.CheckedKey.length
        // this.tableData = this.$store.state.tableDataSearch.slice(0,s)
      },
      // 节点点击
      nodeClickOne(data){
        this.chooseNode = data
        this.handleFilter()
      },
      // 按钮点击
      onBtnClicked: function(domId) {
        console.log('you click:' + domId)
        const today = new Date();
        switch (domId) {
          case 'btnDel'://删除
            
            break
          default:
            break
        }
      },
      // 获取数据
      getList() {
        if(!this.chooseNode){
          this.$message({
            message: `请选择一个大表进行查询！`,
            type: 'error'
          })
          return
        }
        if(this.chooseNode.areaNum!= "▩"){
          this.$message({
            message: `请选择一个大表进行查询！`,
            type: 'error'
          })
          return
        }
        this.listQuery.meterId = this.chooseNode.id
        if(this.listQuery.timer){
          this.listQuery.beginTime = this.$basicFun.dataFormat(this.listQuery.timer[0],'yyyy-MM-dd hh:mm:ss');
          this.listQuery.endTime = this.$basicFun.dataFormat(this.listQuery.timer[1],'yyyy-MM-dd hh:mm:ss');
        }
        this.loading = true
        basicDataApi.yjxllGetList(this.listQuery).then(response => {
          if(response.code == 200){
            response.columnHeaders.forEach((item) => { // 首字母小写
              item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
            })
            this.firstHeaderList = response.columnHeaders
            this.tableData = response.data.data
            this.xDatas = response.data.xDatas
            this.y1Datas = response.data.y1Datas
            this.y2Datas = response.data.y2Datas
            this.getrealChartYjxll();//获取最近30分钟访问情况数据
          }else{
            this.firstHeaderList = []
            this.tableData = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
          this.loading = false
        }).catch(()=>{
          this.firstHeaderList = []
          this.tableData = []
          this.loading = false
        })
      },
      // 搜索
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      // 切换分页
      handleCurrentChange(val) {
        this.tableChooseRow = {}//重置选中项
        this.listQuery.page = val.page
        this.listQuery.limit = val.limit
        this.getList()
      },
      // 行点击
      tableRowClick(row){
        this.tableChooseRow = row
      },
      handleDelete(row) { // 多行删除
        this.tableData.splice(this.tableData.findIndex(e=>e.serialNumber === row.serialNumber),1)
      },
      
    },
  }

</script>

<style lang="scss" scoped>
  
</style>
